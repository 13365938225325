import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/admin/store/goods/page',
    method: 'get',
    params
  })
}
export function getAttrListAPI(params) {
  return http({
    url: '/admin/store/goods/attr/list',
    method: 'get',
    params
  })
}
// delete
export function deleteAPI(id) {
  return http({
    url: '/admin/store/goods/delete',
    method: 'post',
    data: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/admin/store/goods/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/admin/store/goods/edit',
    method: 'post',
    data
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: '/admin/store/goods/detail',
    method: 'get',
    params: { id }
  })
}
// status
export function changeStatusAPI(data) {
  return http({
    url: '/admin/store/goods/status',
    method: 'post',
    data
  })
}
